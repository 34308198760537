(function () {
    var $globalConfData = $('.js-conf-global-data');
    var mqDetector;
    var stringHandlerUtility;
    var notification;
    var searchBox;
    var $previousQueriesWrapper = $('.js-previous-searches-wrapper');
    var printPageBtn = $('.js-print-page');
    var $browser = $('.js-browser');
    var $browserBtnClose = $('.js-browser-close');
    var intervalId;
    var $navbarBtn = $('.ee_main-navigation .navbar-toggle');
    var $stateNav = $('.ee-ico-state-nav');
    var $searchBtn = $('.ee_main-navigation .ee-navbar-toggle-search');

    // Site network list animation when has collapsed
    var $siteNetworkList = $('.js-site-network-list');
    var getParameterByName;
    var clickAbrirAppBanner;

    // Omniture variables for search button
    var $btnSearch = $('.js-btn-search');
    var $btnFooterConfig = $('.js-footer-configuration');

    var $btnMobileCloseBtn = $('.js-close-advisory-tool-tip-mobile');

    var $btnLogOff = $('.js-logOff');
    var $btnLogOffMobile = $('.js-log-off-mobile');

    var pwdReveal;

    // Movil open app banner
    var $btnMobileAppOpenCloseBtn = $('.js-close-app-open-banner-tool-tip-mobile');
    var $btnAppOpenBannerBtn = $('.js-btn-app-open-banner');


    if (!localStorage.getItem("appOpenBanner") || !localStorage.getItem("appDateLastClose")) {

        localStorage.setItem("appOpenBanner", true);
        localStorage.setItem("appDateLastClose", moment());

    } else {

        var maxAllowedDate = moment(localStorage.getItem("appDateLastClose"));
        var currentDate = moment();

        if (currentDate.isAfter(maxAllowedDate)) {
            localStorage.setItem("appOpenBanner", true);
        }
    }

    eeGlobal.appOpenBannerToolTipShow = (localStorage.getItem("appOpenBanner") === "true");

    if (!localStorage.getItem("advisory")) {
        localStorage.setItem("advisory", true)
    }

    eeGlobal.advisoryToolTipShow = (localStorage.getItem("advisory") === "true");

    /*
      Smart Ad Server
    */
    var advertising;

    if (typeof Advertising != 'undefined') {
        advertising = new Advertising();
    }


    //ajaxComplete permite interceptar la request Ajax y examinar la repuesta para determinar si la session caduco
    //Se realiza por seguridad ya que no deberia continuar en la vista actual si la session caduco
    //Archivos modificados: Main.js, MainSimple.js
    $(document).ajaxComplete(function (event, xhr, settings) {
        if (xhr.status === 401) {
            location.reload();
            return;
        }

        var jsonObject = xhr.responseJSON;
        if (jsonObject !== null && jsonObject !== undefined && jsonObject.hasOwnProperty('isSessionExpired') && jsonObject.isSessionExpired) {
            window.location.href = jsonObject.url;
        }
    });

    pwdReveal = new PasswordRevealer({
        $target: $('input[type="password"]')
    });

    notification = new Notification();

    eeGlobal.notification = notification;

    stringHandlerUtility = new StringHandlerUtility();

    eeGlobal.stringHandlerUtility = stringHandlerUtility;

    mqDetector = new MediaQueryDetector({
        $target: $('#mq-detector')
    });

    eeGlobal.mqDetector = mqDetector;

    /*
      Init SearchBox module
    */
    var $searchboxMobile = $('.js-searchbox-mobile');
    var $searchboxHome = $('.js-searchbox-input');
    var $selectSearchBox;

    if ($searchboxMobile.length > 0 && $searchboxHome.length > 0 && mqDetector.isMobile()) {
        $selectSearchBox = $searchboxMobile;
    } else {
        $selectSearchBox = $searchboxHome;
    }

    searchBox = new SearchBox({
        $input: $selectSearchBox,
        $form: $('.js-searchbox-form'),
        $previousQueries: $previousQueriesWrapper,
        $queryLinks: $('.js-previous-searches-wrapper .js-query-links'),
        $inputCities: $('.js-cities-searchbox'),
        $inputAlertOffers: $('.js-keyword-autocomplete-alerts'),
    });
    eeGlobal.searchBox = searchBox;

    // Truncate previous search
    $(document).ready(function () {
        if (!eeGlobal.mqDetector.isDevice('xs', 'lg')) {

            $previousQueriesWrapper.trunk8({
                lines: 2,
                tooltip: false
            });
        } else {

            if (eeGlobal.mqDetector.isDevice('xs') == true) {

                if (eeGlobal.advisoryToolTipShow === true) {
                    $(".ee-tool-tip-advisory-mobile").removeAttr("style");
                }

                if (eeGlobal.appOpenBannerToolTipShow === true) {
                    $(".ee-tool-tip-app-open-banner").removeAttr("style");
                }
            }
        }

        toolTipAdvisory();
    });

    $btnMobileCloseBtn.on('click', function () {

        var $btnMobileClose = $('.js-close-advisory-tool-tip-mobile');
        $(".ee-tool-tip-advisory-mobile").attr("style", "display: none !important");

        var destiny = $btnMobileClose.data("url");
        localStorage.setItem("advisory", false);

        jqxhr = $.ajax({
            type: 'get',
            url: destiny,
            data: "",
            processData: false,
            contentType: false,
            cache: false
        });
    });


    // Omniture search header event
    $btnSearch.on('click', function () {
        var $inputSearchBox = $('.js-searchbox-input.tt-input');
        s.linkTrackVars = 'eVar3,eVar19';
        s.eVar3 = $inputSearchBox.val();
        s.eVar19 = 'Header';
        s.tl(this, 'o', 'busqueda interna');
    });
    //------------------------------------------

    // App Open Banner tool tip funtionality
    $btnMobileAppOpenCloseBtn.on('click', function () {

        var hours = $(this).data("max-hours-hidden-banner");
        localStorage.setItem("appDateLastClose", moment().add(hours, 'hours'));

        var $btnMobileClose = $('.js-close-app-open-banner-tool-tip-mobile');
        $(".ee-tool-tip-app-open-banner").attr("style", "display: none !important");
        localStorage.setItem("appOpenBanner", false);

    });

    $btnAppOpenBannerBtn.on('click', function () {

        // For desktop browser, remember to pass though any metadata on the link for deep linking
        var fallbackLink = 'http://www.elempleo.com/' + window.location.search + window.location.hash;

        // Simple device detection
        var isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod'),
            isAndroid = navigator.userAgent.match('Android');
        // Mobile
        if (isiOS || isAndroid) {
            // Load our custom protocol in the iframe, for Chrome and Opera this burys the error dialog (which is actually HTML)
            // for iOS we will get a popup error if this protocol is not supported, but it won't block javascript
            document.getElementById('loader').src = 'custom-protocol://my-app' + window.location.search + window.location.hash;
            // The fallback link for Android needs to be https:// rather than market:// or the device will try to 
            // load both URLs and only the last one will win. (Especially FireFox, where an "Are You Sure" dialog will appear)
            // on iOS we can link directly to the App Store as our app switch will fire prior to the switch
            // If you have a mobile web app, your fallback could be that instead. 
            fallbackLink = isAndroid ? 'https://play.google.com/store/apps/details?id=com.elempleo.app' :
                'itms-apps://itunes.apple.com/co/app/elempleo/id1422861909?mt=8';
        }
        // Now we just wait for everything to execute, if the user is redirected to your custom app
        // the timeout below will never fire, if a custom app is not present (or the user is on the Desktop)
        // we will replace the current URL with the fallbackLink (store URL or desktop URL as appropriate)
        window.setTimeout(function () { window.location.replace(fallbackLink); }, 1);

        var mobileSO = "Android";
        if (!isAndroid)
        { mobileSO = "IOS"; }

        clickAbrirAppBanner(mobileSO);

    });


    //------------------------------------------
    // Advisory tool tip funtionality
    function toolTipAdvisory() {

        var htmlToolTip = $("#scheduledAdvisoryToolTip").html();
        var advisoryToolTip = $('#js-image-tooltip').tooltip(
            {
                html: true,
                title: htmlToolTip,
                placement: "bottom",
                template: '<div class="tooltip tooltip-advice"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
            });

        advisoryToolTip.unbind('mouseenter mouseleave');

        if (eeGlobal.advisoryToolTipShow === true) {
            advisoryToolTip.tooltip('show');
        }

        closeAdvisoryToolTip(advisoryToolTip);
    }

    function closeAdvisoryToolTip(advisoryToolTip) {

        var $closeAdvisoryToolTipBtn = $('.js-close-advisory-tool-tip');
        $closeAdvisoryToolTipBtn.on('click', function () {

            var htmlToolTip = $("#scheduledAdvisoryToolTip").html();
            var destiny = $(this).data("url");

            localStorage.setItem("advisory", false);
            advisoryToolTip.tooltip('hide');

            jqxhr = $.ajax({
                type: 'get',
                url: destiny,
                data: "",
                processData: false,
                contentType: false,
                cache: false
            });
        });
    }
    /*
      Doble binding
    */
    $navbarBtn.on('click', function (event) {
        var $target = $($searchBtn.data('target'));
        var isCollapseIn;

        isCollapseIn = $target.hasClass('in') && $target.hasClass('collapse')

        if (isCollapseIn) {
            $target.collapse('hide');
            $searchBtn.addClass('collapsed');
        }

        $stateNav.toggleClass("hidden-ico");
    });
    $searchBtn.on('click', function (event) {
        var $target = $($navbarBtn.data('target'));
        var isCollapseIn;

        isCollapseIn = $target.hasClass('in') && $target.hasClass('collapse')

        if (isCollapseIn) {
            $target.collapse('hide');
        }
    });

    /* TODO: Review this code */
    $.extend({
        getUrlVars: function () {
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            return vars;
        },
        getUrlVar: function (name) {
            return $.getUrlVars()[name];
        }
    });

    // Added animation to site network list when has collapsed
    $siteNetworkList.on('shown.bs.collapse', function (e) {
        $('html, body').animate({
            scrollTop: $(this).offset().top
        }, 500);
    });

    // Javascript notification on your website for the out-dated browsers
    // Using library browser-update.org
    this.$buoop = {
        vs: {
            i: 8,
            f: 16,
            o: 12,
            s: 5,
            c: 23
        },             // browser versions to notify
        reminder: 1,   // atfer how many hours should the message reappear
        // 0 = show all the time
        //text: "",    // custom notification html text
        //text_xx: "", // custom notification text for language "xx"
        // e.g. text_de for german and text_it for italian
        newwindow: true // open link in new window/tab
    };

    printPageBtn.on('click', function () {
        window.print();
    });

    // Omniture log off event
    $btnLogOff.on('click', function () {

        localStorage.setItem("advisory", true);

        // Omniture event
        s.linkTrackVars = 'events,eVar14,eVar12';
        s.linkTrackEvents = 'event4';
        s.events = 'event4';
        s.eVar14 = 'signed out';
        s.eVar12 = $('span.ee-user-fullname').text().trim();
        s.tl(true, 'o', 'not signed in');
    });

    $btnLogOffMobile.on('click', function () {

        localStorage.setItem("advisory", true);

        // Omniture event
        s.linkTrackVars = 'events,eVar14,eVar12';
        s.linkTrackEvents = 'event4';
        s.events = 'event4';
        s.eVar14 = 'signed out';
        s.eVar12 = $('span.ee-user-fullname').text().trim();
        s.tl(true, 'o', 'not signed in');

        //localStorage.setItem("appOpenBanner", true);
    });

    clickAbrirAppBanner = function (sistemaOperativo) {
        s.linkTrackVars = "events,eVar97";
        s.linkTrackEvents = "event144";
        s.eVar97 = sistemaOperativo;
        s.events = "event144"
        s.tl(true, "o", sistemaOperativo);
    }

    // Omniture expired session
    // Functions
    getParameterByName = function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    // Omniture expired session
    if (getParameterByName("session") === "1") {
        s.linkTrackVars = 'events,eVar14,eVar12';
        s.linkTrackEvents = 'event4';
        s.events = 'event4';
        s.eVar14 = 'not signed in';
        s.eVar12 = 'user signed out';
        s.tl(true, 'o', 'signed out');
    }

    //Hide alert detect naviagator ie
    $browserBtnClose.on('click', function () {
        $browser.addClass('hide');
    });

    //Function to detect version of the browser
    detectBrow = function () {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        var chr = ua.indexOf('Chrome/');
        var version = -1;

        if (msie != -1) {
            if (msie > 0) {
                // IE 10 or older => return version number
                version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }
            if (version === -1 || version > 8) {
                clearInterval(intervalId);
            }
        }
        if (chr != -1) {
            // Chrome return version number
            version = parseInt(ua.substring(chr + 7, ua.indexOf('.', chr), 10));
        }
        if (version === -1 || version > 20) {
            clearInterval(intervalId);
        }
        else {
            $browser.removeClass('hide');
        }
    };
    detectBrow();
    intervalId = setInterval(detectBrow, 216000000);

})();
