$(document).ready(function() {
    validatePolicyNavigation();
	  $('.btnAcceptPolicyNavigationCO').click(function() {
          document.cookie = "cookies-info=true;expires=" + getExpireTime() + ";path=/";
          $('.politics_cookie').hide();

          dataLayer.push({ 'event': 'aceptacion_cookies' });
    });
    $('.btnAcceptPolicyNavigationCR').click(function() {        
		document.cookie = "acceptPolicyNavigationCR=true;expires=" + getExpireTime() + ";path=/";
        $('.politics_cookie').hide();
    });
});

function validatePolicyNavigation() {
    if ($('#culture').val() == 'CO') {
        switch (document.cookie.indexOf('cookies-info=') === -1 ? '' : ("; " + document.cookie).split('; cookies-info=')[1].split(';')[0]) {
            case "true":
                break;
            case null:
                document.getElementById('politics_cookieCO').style.visibility = 'visible'
                break;
            default:
                document.getElementById('politics_cookieCO').style.visibility = 'visible'
                break;
        }
    }
    if ($('#culture').val() == 'CR') {
		switch (document.cookie.indexOf('acceptPolicyNavigationCR=') === -1 ? '' : ("; " + document.cookie).split('; acceptPolicyNavigationCR=')[1].split(';')[0]) {
            case "true":
                break;
            case null:
                document.getElementById('politics_cookieCR').style.visibility = 'visible'
                break;
            default:
                document.getElementById('politics_cookieCR').style.visibility = 'visible'
                break;
        }
    }
}

function getExpireTime() {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * 60 * 60 * 24 * 90; // 90 días
    now.setTime(expireTime);
    return now.toUTCString();
}