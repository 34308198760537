!function (global) {
  'use strict';

  var previousStringHandlerUtility = global.StringHandlerUtility;

  function StringHandlerUtility() {

    function normalizeString(string) {
      var charMapObject = {
        'áä': 'a',
        'éë': 'e',
        'íï': 'i',
        'óö': 'o',
        'úü': 'u',
        'ñ': 'n'
      };

      for (var char in charMapObject) {
        var regex = new RegExp('[' + char + ']', 'gi');
        string = string.replace(regex, charMapObject[char]);
      }

      return string;
    }

    return {
      normalizeString: normalizeString
    }
  }

  StringHandlerUtility.noConflict = function noConflict() {
    global.StringHandlerUtility = previousStringHandlerUtility;
    return StringHandlerUtility;
  };

  global.StringHandlerUtility = StringHandlerUtility;
}(this);
