!function (global) {
    'use strict';

    var previousSearchBox = global.SearchBox;

    function SearchBox(options) {
        var options = options || {};
        var $htmlBody = $('html, body');
        var initialCity = {};
        var searchBoxAutocomplete;
        var searchBoxCityAutocomplete;
        var AlertAutocomplete;
        var searchUrl;
        var $input;
        var $form;
        var $inputCities;
        var $inputAlertsOffers;
        // Helpers
        var isFirstSearch = true;

        // Previous queries
        var queryList = [];
        var $previousQueries;
        var $queryLinks;

        function init() {
            var inputValues = {query: '', city: '', id: ''};
            $input = options.$input;
            $form = options.$form;
            $previousQueries = options.$previousQueries;
            $queryLinks = options.$queryLinks;
            $inputCities = options.$inputCities;
            $inputAlertsOffers = options.$inputAlertOffers;

            searchUrl = $form.data('search-url');
            // init typeahead
            searchBoxAutocomplete = new AutocompleteBox({
                $input: $input,
                url: $input.data('search-url')
            });
            
            if (!!$inputAlertsOffers.length) {
                AlertAutocomplete = new AutocompleteBox({
                    $input: $inputAlertsOffers,
                    url: $input.data('search-url')
                });
            }

            if(!!$inputCities.length) {
                searchBoxCityAutocomplete = new AutocompleteBox({
                    $input: $inputCities,
                    url: $inputCities.data('search-url')
                });
            }

            if (store.enabled && $previousQueries !== undefined && $queryLinks !== undefined && !eeGlobal.mqDetector.isDevice('xs')) {
                getPreviousQueries();

                if (queryList.length) {
                    renderPreviousQueries();
                    $previousQueries.removeClass('hidden');
                }
            }

            if(inputValues.city === "" && !!$inputCities.length) {
                searchBoxCityAutocomplete.promiseBhCities.done(function() {
                    initialCity.name = searchBoxCityAutocomplete.setDefaultCity();
                    initialCity.normalizedName = searchBoxCityAutocomplete.setDefaultNormalizedCity();

                    inputValues.city = initialCity.normalizedName;
                    $inputCities.typeahead('val', initialCity.name);
                });
            } else if (!$inputCities.length) {
                inputValues.city = "";
            }

            // Bindings
            // Form
            $form.bind('keypress keydown keyup', function (e) {
                inputValues.query = $input.typeahead('val');

                if (e.keyCode == 13 && !$(e.target).hasClass('js-cities-searchbox')) {
                    goToSearch(inputValues);
                    return false;
                }
            });

            $form.submit(function (event) {
                inputValues.query = $input.typeahead('val');
                goToSearch(inputValues);
                return false;
            });

            $input.add($inputCities).on('focus', function(){
                if (eeGlobal.mqDetector.isDevice('xs')) {
                    $htmlBody.animate({
                        scrollTop: $form.offset().top - 8
                    }, 500);
                }
            });

            // Typeahead
            $input.on('typeahead:select', function (event, suggestion) {
                inputValues.query = suggestion.name;
                goToSearch(inputValues);
            });

            $inputCities
                .on('typeahead:select', function (event, suggestion) {
                    inputValues.id = suggestion.id;
                    inputValues.city = suggestion.normalizedName;
                    initialCity.name = suggestion.nameToShow;
                    $inputCities.blur();
                })
                .on('typeahead:active', function() {
                    $inputCities.typeahead('val', '');
                })
                .on('blur', function () {
                    $inputCities.val(initialCity.name);
                });
        }

        function setPlaceHolder(placeHolderText) {
            $input.attr("placeholder", capitalizeFirstLetter(placeHolderText));
            $inputCities.val();
        }

        function setCityValue(value) {
            $inputCities.typeahead('val', (value === null)? 'Todas las ciudades': value);
            initialCity.name = $inputCities.typeahead('val');
        }

        function removeSpecialChars(text) {
            return encodeURI(text.replace(/[^a-zA-ZÀ-ú0-9 +.&"'-]/g, "").replace('&', '%26').trim());
        }

        function capitalizeFirstLetter(text) {
            text = text.toLowerCase();
            return text.charAt(0).toUpperCase() + text.slice(1);
        }

        function goToSearch(queryObj) {
            var search;
            var cleanProfessionQuery = "";
            var cleanCityQuery;
            var pathUrl;

            if (isFirstSearch) {
                isFirstSearch = false;

                if(queryObj.query  != undefined && queryObj.query.length > 0) {
                    addQuery(queryObj);
                    cleanProfessionQuery = removeSpecialChars(queryObj.query.replace(/\s+/g, '-'));
                    // console.log(cleanQuery);
                    cleanProfessionQuery = cleanProfessionQuery.replace(/\+/g, '%2B').replace(/\s/g, "+");
                    cleanProfessionQuery = "?trabajo=" + cleanProfessionQuery;
                }

                // console.log(cleanQuery);
                pathUrl = (queryObj.id === "0" || !$inputCities.length || queryObj.city === "") ? searchUrl : searchUrl + queryObj.city;
                search = pathUrl + cleanProfessionQuery;
                // console.log(search);
                window.location.href = search;
            }
        }

        function addQuery(queryObj) {
            for (var i = queryList.length-1; i >= 0; i--) {
                if (queryList[i].query === queryObj.query) {
                    queryList.splice(i, 1);
                }
            }

            queryList.unshift(queryObj);

            if (queryList.length > 3) {
                queryList.pop();
            }

            setPreviousQueries();
        }

        function getPreviousQueries() {
            var validQueryList = [];

            queryList = store.get('ee-previous-searches');

            if (!queryList) {
                queryList = [];
            }

            for (var k in queryList) {
                if(typeof queryList[k] === 'object'){
                    validQueryList.push(queryList[k]);
                }
            }

            queryList = validQueryList;
        }

        function setPreviousQueries() {
            if(store.enabled && !eeGlobal.mqDetector.isDevice('xs')) {
                store.set('ee-previous-searches', queryList);
            }
        }

        function renderPreviousQueries() {
            queryList.map(function(item, index){
                if (item.query !== '') {
                    var cleanQuery;
                    var a;

                    cleanQuery = removeSpecialChars(item.query);
                    cleanQuery = cleanQuery.replace(/\+/g, '%2B').replace(/\s/g, "+");

                    a = $('<a />');
                    a.attr('href', searchUrl + item.city + "?trabajo=" + cleanQuery);
                    a.attr('title', item.query.slice(0, 100));
                    a.text(item.query);

                    $queryLinks.append(a);

                    if ((index + 1) < queryList.length) {
                        $queryLinks.append(', ');
                    }
                }
            });
        }

        init();

        return {
            setPlaceHolder: setPlaceHolder,
            setCityValue: setCityValue
        }
    }

    SearchBox.noConflict = function noConflict() {
        global.SearchBox = previousSearchBox;
        return SearchBox;
    };

    global.SearchBox = SearchBox;
}(this);
