(function () {
  var $modalLogin;

 // Omniture
  var s_code;
  var omnitureLogin = {};
  var $conf = $('.js-config');
  $modalLogin = $('.js-modal-login');
  var $globalConfData = $('.js-conf-global-data');
  var $facebookPixelEnable = $globalConfData.data('facebookpixel-enable') ? $globalConfData.data('facebookpixel-enable').toLowerCase() == "true" : false;

  omnitureLogin = {
    pageName: "elempleo: personas: login: lightbox",
    channel: "elempleo: personas"
  }

  // Omniture validation
  if($conf.data('omniture-page-name') != undefined) {
    omnitureLogin.pageName = $conf.data('omniture-page-name');
  }

  if($conf.data('omniture-channel') != undefined) {
    omnitureLogin.channel = $conf.data('omniture-channel');
  }

  function setOmnitureLogin() {
    s.pageName = omnitureLogin.pageName;
    s.channel = omnitureLogin.channel;
    s_code = s.t();
    if (s_code) document.write(s_code);
  }

  // Omniture event
  $modalLogin.on('show.bs.modal', function () {
    // Omniture for login lightbox
    setOmnitureLogin();
  });

  //Omniture Intention de Clics
  $("#btnlogin").bind("click", function () {
    s.linkTrackVars = "events,eVar91";
    s.linkTrackEvents = "event113";
    s.events = "event113";
    s.eVar91 = "intencion desde " + window.location.pathname;
    s.tl(this, "o", "intencion login");
  });

  $("#btnRegisterCV").bind("click", function () {
    s.linkTrackVars = "events,eVar91";
    s.linkTrackEvents = "event112";
    s.events = "event112";
    s.eVar91 = "intencion desde " + window.location.pathname;
    s.tl(this, "o", "intencion registro");

    if ($facebookPixelEnable) {
      fbq('track', 'ViewContent');
    }
  });

  $("#btnRegisterCVHome").bind("click", function () {
    s.linkTrackVars = "events,eVar91";
    s.linkTrackEvents = "event112";
    s.events = "event112";
    s.eVar91 = "intencion desde " + window.location.pathname;
    s.tl(this, "o", "intencion registro");

    if ($facebookPixelEnable) {
      fbq('track', 'ViewContent');
    }
  });
})();
